.test-list {
  margin: 0px 0px 50px 0px;
}
.test-list__caption {
  margin: 0px;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
}
.test-list__loading {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
  letter-spacing: 1.2px;
}
.test-list__empty {
  margin: 15px 0px 0px;
  color: var(--main-color);
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
}
.test-list__container {
  margin: 20px 0px 0px 0px;
  list-style-type: none;
  padding: 0px;
}

@media all and (max-width: 750px) {
  .test-list__caption {
    font-size: 16px;
  }
}