.test-item {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 30px 40px;
  margin: 0px 0px 20px;
  transition: .3s ease-in-out;
}
.test-item__container {
  display: flex;
}
.test-item__section-info {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.test-item__section-chart {
  margin: 0px 0px 0px auto;
  width: 180px;
  flex-shrink: 0;
}
.test-item__name {
  margin: 0px 0px 20px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  text-align: left;
  color: var(--title-color);
}
.test-item__info-list {
  display: flex;
  margin: auto 0px 0px 0px;
  position: relative;
}
.test-item__info-item {
  padding-left: 40px;
  margin: 0px 35px 0px 0px;
  position: relative;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
  text-align: left;
  color: var(--title-color);
}
.test-item__info-item_type_questions::before {
  content: "";
  background-image: url('../../images/questions-color.png');
  background-size: 30px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: -5px;
  z-index: 5;
}
.test-item__info-item_type_attempts::before {
  content: "";
  background-image: url('../../images/attempts-color.png');
  background-size: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: -5px;
  z-index: 5;
}
.test-item__info-item_type_score::before {
  content: "";
  background-image: url('../../images/score-color.png');
  background-size: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: 5;
}
.test-item__control {
  margin: auto 0px 0px 0px;
  display: flex;
}
.test-item__btn-attempt {
  margin: 10px 25px 0px 0px;
  background-color: var(--title-color);
  padding: 6.5px 45px 6px 15px;
  max-width: max-content;
}
.test-item__btn-attempt::after {
  content: "";
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.test-item__btn-attempt_type_show::after {
  transform: rotate(90deg);
}
.test-item__btn-start {
  background-color: var(--main-color);
  padding: 6.5px 45px 6px 15px;
  max-width: max-content;
  text-decoration: none;
  text-transform: none;
  margin: 10px 25px 0px 0px;
}
.test-item__btn-start::after {
  content: "";
  background-image: url('../../images/start-white.png');
  background-size: 22px;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 5;
  transition: .9s ease-in-out;
}
.test-item__btn-try {
  background-color: var(--fourth-light-color);
  padding: 6.5px 45px 6px 15px;
  max-width: max-content;
  text-decoration: none;
  text-transform: none;
  margin: 10px 0px 0px 0px;
}
.test-item__btn-try::after {
  content: "";
  background-image: url('../../images/start-white.png');
  background-size: 22px;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 5;
  transition: .9s ease-in-out;
}
.test-item__attempt-list {
  list-style-type: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  transition: .7s ease-in-out;
  position: relative;
}
.test-item__attempt-list_type_show {
  max-height: auto;
  opacity: 1;
  overflow-y: visible;
}
.test-item__attempt-list_type_hide {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
}
.test-item__attempt-item {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-top: 1px solid var(--border-card-color);
}
.test-item__attempt-item:first-of-type {
  margin-top: 30px;
}
.test-item__attempt-percent {
  min-width: 60px;
  margin: 0px 50px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  color: var(--main-color);
}
.test-item__attempt-text {
  margin: 0px 30px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  letter-spacing: 1.2;
  color: var(--subtitle-color);
}
.test-item__attempt-date {
  margin: 0px 0px 0px auto;
  padding-left: 40px;
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  color: var(--subtitle-color);
}

@media all and (max-width: 750px) {
  .test-item {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    margin: 0px 0px 15px;
  }
  .test-item__container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .test-item__section-info {
    width: 100%;
  }
  .test-item__info-list {
    display: flex;
    flex-direction: column;
  }
  .test-item__info-item {
    padding-left: 35px;
    margin: 0px 0px 15px 0px;
    position: relative;
    font-size: 16px;
  }
  .test-item__info-item_type_questions::before {
    background-size: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0px;
    top: -5px;
  }
  .test-item__info-item_type_attempts::before {
    background-size: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0px;
    top: -5px;
  }
  .test-item__info-item_type_score::before {
    background-size: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0px;
    top: -5px;
  }
  .test-item__section-chart {
    margin: 10px auto 10px;
    width: 180px;
  }
  .test-item__control {
    margin: auto 0px 0px 0px;
    display: flex;
    flex-direction: column-reverse;
  }
  .test-item__btn-attempt {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
    width: 100%;
  }
  .test-item__btn-start {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
    width: 100%;
  }
  .test-item__btn-try {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
    width: 100%;
  }
  .test-item__attempt-percent {
    margin: 0px 30px 0px 0px;
  }
  .test-item__attempt-text {
    margin: 0px;
  }
  .test-item__attempt-date {
    display: none;
  }
}
