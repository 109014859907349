.page {
  background-color: var(--background-main-color);
  width: 100%;
  height: 100vh;
}
.page__img {
  background-image: url('../../images/background-page.jpg');
  background-position: 0px 0px 0px -600px;
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(100% - 600px);
  height: 100vh;
}
.page__img::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, .7);
  width: 100%;
  height: 100vh;
}
.page__section {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 600px;
  height: 100vh;
  background-color: var(--background-main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.page__container {
  position: relative;
  padding: 40px 60px;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.page__logo {
  margin: 0px 0px 100px 0px;
  height: 50px;
  object-fit: contain;
  object-position: center;
  user-select: none;
  pointer-events: none;
}
.page__title {
  margin: 0px 0px 12px 0px;
  font-weight: bold;
  font-size: 32px;
  font-style: normal;
  line-height: 1.15;
  text-align: center;
  color: var(--title-color);
}
.page__subtitle {
  margin: 20px 0px 12px 0px;
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  text-align: center;
  color: var(--title-color);
}
.page__roles {
  width: 100%;
  margin: 0px 0px 30px 0px;
  display: flex;
  align-items: center;
}
.page__role {
  width: 100%;
  margin: 0px 8px 0px 0px;
  padding: 12px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--main-color);
  background-color: var(--main-color);
  border-radius: 100px;
  transition: all .3s ease-in-out;
}
.page__role:last-of-type {
  margin: 0px;
}
.page__role:hover {
  cursor: pointer;
  opacity: .7;
}
.page__role_status_active {
  background-color: var(--white-color);
  user-select: none;
  pointer-events: none;
}
.page__role-name {
  margin: 0px;
  color: var(--white-color);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.2px;
  transition: all .3s ease-in-out;
}
.page__role_status_active .page__role-name {
  color: var(--title-color);
}
.page__role-icon {
  margin: 0px 8px 0px 0px;
  width: 16px;
  height: 16px;
  transition: all .3s ease-in-out;
}
.page__role-icon_type_user {
  background-image: url('../../images/icons/main-white/user.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.page__role_status_active .page__role-icon_type_user {
  background-image: url('../../images/icons/main-black/user.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.page__role-icon_type_moderator {
  background-image: url('../../images/icons/main-white/shield.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.page__role_status_active .page__role-icon_type_moderator {
  background-image: url('../../images/icons/main-black/shield.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.page__caption {
  margin: 20px 0px 0px 0px;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  text-align: center;
  color: var(--title-color);
  transition: all .3s ease-in-out;
}
.page__caption:hover {
  cursor: pointer;
  color: var(--main-color);
}
.page__contact-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  list-style-type: none;
  margin: auto 0px 0px 0px;
}
.page__contact-item {
  margin: 0px 0px 15px 0px;
}
.page__contact-item:last-of-type {
  margin: 0px;
}
.page__contact-title {
  margin: 0px 0px 5px 0px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  text-align: center;
  color: var(--title-color);
}
.page__contact-caption {
  width: 100%;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  text-align: center;
  color: var(--title-color);
}

@media all and (max-width: 750px) {
  .page__img {
    display: none;
  }
  .page__section {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    align-items: flex-start;
  }
  .page__container {
    position: relative;
    padding: 18px 25px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .page__logo {
    margin: 0px 0px 40px 0px;
    height: 30px;
    object-fit: contain;
    object-position: center;
    user-select: none;
    pointer-events: none;
  }
  .page__title {
    margin: 0px 0px 50px 0px;
    font-weight: 700;
    font-size: 24px;
  }
}