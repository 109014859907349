html {
  background-color: #ebebeb;
}
.page {
  --title-color: #2E3033;
  --subtitle-color: #4F5665;
  --white-color: #ffffff;
  --background-main-color: #ebebeb;
  --background-secondary-color: #fafafa;
  --background-third-color: #F3F3F3;
  --main-color: #e21a1a;
  --main-light-color: #5BC1EE;
  --secondary-color: #5EB9AF;
  --secondary-light-color: #3ABD98;
  --third-light-color: #FA7565;
  --fourth-light-color: #FBB444;
  --five-light-color: #8B00FF;
  --border-card-color: #DDDDDD;
  color: var(--subtitle-color);
  background-color: var(--background-main-color);
  box-sizing: border-box;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  position: relative;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0px auto;
}
.btn-icon {
  position: relative;
  margin: 0px;
  padding: 0px;
  border: none;
  border-radius: 8px;
  outline: none;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  color: var(--white-color);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  background: var(--slider-color);
  text-decoration: none;
  transition: all .3s ease-in-out;
}
.btn-icon::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  background-size: 20px;
  width: 20px;
  height: 20px;
}
.btn-icon_margin_left {
  margin: 0px 0px 0px 12px;
}
.btn-icon_color_accent-red {
  color: var(--white-color);
  background: var(--main-color);
}
.btn-icon_color_accent-red:hover {
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
  box-shadow: 0px 8px 12px -8px rgba(226,26,26, 0.9);
}
.btn-icon_color_accent-orange {
  color: var(--white-color);
  background: var(--fourth-light-color);
}
.btn-icon_color_accent-orange:hover {
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
  box-shadow: 0px 8px 12px -8px rgba(213, 102, 0, 0.9);
}
.btn-icon_type_edit::after  {
  background-image: url('../../images/icons/main-white/edit.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_download::after   {
  background-image: url('../../images/icons/main-white/download.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_first::after   {
  background-image: url('../../images/icons/main-white/double-arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
.btn-icon_type_next::after   {
  background-image: url('../../images/icons/main-white/arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(270deg);
}
.btn-icon_type_prev::after   {
  background-image: url('../../images/icons/main-white/arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.btn-icon_type_last::after   {
  background-image: url('../../images/icons/main-white/double-arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_pass::after   {
  background-image: url('../../images/icons/main-white/shield.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_block {
  background: var(--border-card-color);
  pointer-events: none;
  cursor: not-allowed;
}
.btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 10px 45px 10px 15px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  flex-shrink: 0;
}
.btn:hover {
  opacity: .6;
  cursor: pointer;
}
.btn_type_add {
  background-color: var(--main-color);
}
.btn_type_add::after {
  content: '';
  background-image: url('../../images/add.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 11px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_edit {
  background-color: var(--fourth-light-color);
}
.btn_type_edit::after {
  content: '';
  background-image: url('../../images/edit.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 11px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_save {
  background-color: var(--main-color);
}
.btn_type_save::after {
  content: '';
  background-image: url('../../images/save-white.png');
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 11px;
}
.btn_type_export {
  background-color: var(--title-color);
  text-decoration: none;
  text-transform: none;
}
.btn_type_export::after {
  content: '';
  background-image: url('../../images/export.png');
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 11px;
}
.btn_type_block {
  user-select: none;
  pointer-events: none;
  background-color: var(--background-main-color);
  color: var(--background-third-color);
}
.btn_type_loading {
  pointer-events: none !important;
  user-select: none !important;
  background-color: var(--background-third-color) !important;
}
.btn_margin-left_auto {
  margin-left: auto;
}
.btn_margin-left_20 {
  margin-left: 20px;
}
.search {
  background: var(--background-third-color);
  border: none;
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  color: var(--title-color);
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  outline: none;
  padding: 8px 11px;
  transition: all .3s ease-in-out;
  width: 100%;
}
.search_border_right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.search_border_left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.search_margin_left {
  margin-left: 20px;
}
.search:focus {
  border: 2px solid var(--fourth-light-color);
}
.search:hover {
  border: 2px solid var(--fourth-light-color);
}
.search-btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 10px 16px 10px 16px;
  background-color: var(--fourth-light-color);
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  flex-shrink: 0;
}
.search-btn_border_left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.search-btn_border_right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.search-btn_margin_right {
  margin-right: 20px;
}
.search-btn_margin_left {
  margin-left: 20px;
}
.search-btn:hover {
  cursor: pointer;
  opacity: .7;
}
.search__separate {
  margin: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ReactCollapse--collapse {
  transition: height 500ms;
}
.select-wrapper {
  width: calc(100% - 20px);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 100px;
  position: relative;
  color: var(--title-color);
  background-color: var(--white-color);
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.5px;
  margin: 0px;
  transition: .3s ease-in-out;
}
.select-wrapper > select {
  padding: 12px 22px;
  width: 100%;
  background-color: transparent;
  border-radius: 100px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.5px;
  color: var(--title-color);
  background-color: var(--white-color);
  outline: none;
  appearance: none;
  transition: .3s ease-in-out;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select-option {
  max-width: 400px;
}

.select-wrapper > select:focus {
  outline: none;
}
.select-wrapper > select > option {
  outline: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.5px;
}
.select-wrapper > select::-ms-expand{
  display: none;
}
.select-arrow {
  position: absolute;
  border: 8px solid transparent;
  border-bottom: 0;
  right: 11px;
}
.select-arrow:nth-child(2) {
  top: 18px;
  border-top-color: black;
}
.select-arrow:nth-child(3) {
  top: 15px;
  border-top-color: #FFF;
}
.checkbox {
  margin: 24px 0px;
  display: block;
  cursor: pointer;
}
.checkbox input {
  display: none;
}
.checkbox input + span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  padding-left: 22px;
  padding-top: 2px;
  display: block;
  position: relative;
}
.checkbox input + span:not(:empty) {
  padding-left: 30px;
}
.checkbox input + span:before, .checkbox input + span:after {
  content: '';
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 20%;
  left: 0;
  top: 0;
  position: absolute;
}
.checkbox input + span:before {
  background: var(--border-card-color);
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.checkbox input + span:after {
  background: var(--white-color);
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.checkbox input:checked + span:before {
  transform: scale(1.04);
  background: var(--main-color);
  background-image: url('../../images/checkbox-img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
}
.checkbox input:checked + span:after {
  transform: scale(0);
  transition: transform 0.1s ease;
}
.checkbox:hover input + span:before {
  transform: scale(0.92);
}
.checkbox:hover input + span:after {
  transform: scale(0.74);
}
.checkbox:hover input:checked + span:after {
  transform: scale(0);
}

@media all and (max-width: 750px) {
  .container {
    width: 100%;
    padding: 0px 12px;
    box-sizing: border-box;
  }
  .select-wrapper {
    width: 100%;
  }
  .select-wrapper > select {
    font-size: 14px;
  }
  .checkbox input + span {
    font-size: 14px;
    padding-top: 3px;
  }
}