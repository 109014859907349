.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login__form-line {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 100px;
  margin-bottom: 35px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  position: relative;
}
.login__title {
  margin: 0px;
  color: var(--title-color);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.login__input {
  width: 100%;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  letter-spacing: 1.2px;
  border: 2px solid var(--white-color);
  transition: .3s ease-in-out;
}
.login__input::placeholder {
  font-size: 14px;
  line-height: 18px;
  color: var(--subtitle-color);
}
.login__input:focus {
  border: 2px solid var(--main-color);
}
.login__input-error {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  color: var(--main-color);
  top: calc(100% + 10px);
  left: 28px;
  opacity: 0;
  transition: .3s ease-in-out;
}
.login__input-error_active {
  opacity: 1;
}
.login__submit {
  position: relative;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.login__submit-error {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.15px;
  color: var(--main-color);
  transition: all .3s ease-in-out;
}
.login__submit-error_type_show {
  opacity: 1;
}
.login__submit-error_type_hide {
  opacity: 0;
}
.login__submit-button {
  margin-top: 25px;
  width: 100%;
  background-color: var(--main-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.4px;
  padding: 17.5px 22.5px;
  outline: none;
  transition: all .3s ease-in-out;
  position: relative;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.login__submit-button:hover {
  cursor: pointer;
  opacity: .7;
}
.login__submit-button_type_block {
  pointer-events: none;
  user-select: none;
  background-color: var(--white-color);
  color: var(--background-third-color);
}

@media all and (max-width: 750px) {
  .login__input {
    padding: 8px 18px;
    font-size: 16px;
  }
  .login__input::placeholder {
    font-size: 14px;
  }
  .login__submit-button {
    margin-top: 15px;
    font-size: 16px;
    line-height: 19px;
    padding: 12.5px 40px;
  }
}