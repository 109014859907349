.result-item {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 30px 40px;
  margin: 0px 0px 20px;
  transition: .3s ease-in-out;
}
.result-item__container {
  display: flex;
}
.result-item__section-info {
  margin: 0px 40px 0px 0px;
  display: flex;
  flex-direction: column;
}
.result-item__section-chart {
  margin: 0px 0px 0px auto;
  width: 120px;
  flex-shrink: 0;
}
.result-item__name {
  margin: 0px 0px 10px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  text-align: left;
  color: var(--title-color);
}
.result-item__test {
  margin: 0px 0px 20px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.25;
  text-align: left;
  color: var(--title-color);
}
.result-item__control {
  margin: auto 0px 0px 0px;
  display: flex;
}
.result-item__btn-attempt {
  margin: 10px 25px 0px 0px;
  background-color: var(--title-color);
  padding: 6.5px 45px 6px 15px;
  max-width: max-content;
}
.result-item__btn-attempt::after {
  content: "";
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.result-item__btn-attempt_type_show::after {
  transform: rotate(90deg);
}
.result-item__btn-export {
  margin: 10px 25px 0px 0px;
  background-color: var(--main-color);
  padding: 6.5px 15px 6px 15px;
  max-width: max-content;
  text-decoration: none;
}
.result-item__attempt-list {
  list-style-type: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  transition: .7s ease-in-out;
  position: relative;
}
.result-item__attempt-list_type_show {
  max-height: auto;
  opacity: 1;
  overflow-y: visible;
}
.result-item__attempt-list_type_hide {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
}
.result-item__attempt-item {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-top: 1px solid var(--border-card-color);
}
.result-item__attempt-item:first-of-type {
  margin-top: 30px;
}
.result-item__attempt-percent {
  min-width: 60px;
  margin: 0px 50px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  color: var(--main-color);
}
.result-item__attempt-text {
  margin: 0px 30px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  letter-spacing: 1.2;
  color: var(--subtitle-color);
}
.result-item__attempt-date {
  margin: 0px 20px 0px auto;
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;
  color: var(--subtitle-color);
}
.result-item__btn-export-attempt {
  margin: 0px;
  background-color: var(--main-color);
  padding: 6.5px 15px 6px 15px;
  max-width: max-content;
  text-decoration: none;
}

@media all and (max-width: 750px) {
  .result-item {
    padding: 8px 12px;
  }
  .result-item__container {
    display: flex;
    flex-direction: column-reverse;
  }
  .result-item__section-info {
    margin: 0px auto 0px;
  }
  .result-item__section-chart {
    margin: 10px auto 20px;
  }
  .result-item__control {
    margin: 0px;
    flex-direction: column-reverse;
  }
  .result-item__btn-attempt {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
  }
  .result-item__btn-export {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
  }
  .result-item__attempt-item {
    flex-wrap: wrap;
  }
  .result-item__attempt-text {
    display: none;
  }
  .result-item__btn-export-attempt {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
    width: 100%;
  }
}