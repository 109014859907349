.header {
  width: 100%;
  border-bottom: 1px solid var(--border-card-color);
}
.header__container {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  width: 100%;
}
.header__logo {
  margin: 0px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  user-select: none;
  pointer-events: none;
}
.header__name {
  margin: 0px 25px 0px auto;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  text-align: center;
  color: var(--title-color);
}
.header__btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 7px 36px 7px 12px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  text-decoration: none;
}
.header__btn:hover {
  opacity: .7;
  cursor: pointer;
}
.header__btn-person {
  margin: 0px 15px 0px 0px;
  background-color: var(--title-color);
}
.header__btn-person_type_block {
  pointer-events: none;
  user-select: none;
}
.header__btn-person::after {
  content: '';
  background-image: url('../../images/person-white.png');
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 5;
}
.header__btn-logout {
  margin: 0px;
  background-color: var(--main-color);
}
.header__btn-logout::after {
  content: '';
  background-image: url('../../images/logout-white.png');
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 5;
}

@media all and (max-width: 750px) {
  .header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 12px;
  }
  .header__name {
    margin: 20px auto 0px;
  }
  .header__btn {
    margin: 20px auto 0px; 
  }
}