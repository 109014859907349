.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-main-color);
}
.main__title {
  position: relative;
  padding-left: 110px;
  margin: 40px 0px 50px 0px;
  font-weight: 700;
  font-size: 34px;
  font-style: normal;
  line-height: 1.15;
  color: var(--title-color);
}
.main__title::before {
  content: '';
  background-image: url('../../images/main-title-picture.png');
  background-size: 80px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0px;
  top: 20px;
  z-index: 5;
}
.main__subtitle {
  position: relative;
  margin: 40px 0px 10px 0px;
  font-weight: 700;
  font-size: 28px;
  font-style: normal;
  line-height: 1.15;
  color: var(--title-color);
}
.main__nav {
  width: 100%;
  border-bottom: 1px solid var(--border-card-color);
  margin: 12px 0px 10px;
  padding: 0px;
}
.main__nav-link {
  display: inline-block;
  border: 1px solid transparent;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 8px 20px;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  text-decoration: none;
  color: var(--title-color);
}
.main__nav-link_type_active {
  background-color: var(--main-color);
  color: var(--white-color);
}

@media all and (max-width: 750px) {
  .main__title {
    padding-left: 0px;
    margin: 20px 0px 30px 0px;
    font-size: 20px;
    text-align: center;
  }
  .main__title::before {
    content: none;
  }
  .main__nav {
    display: flex;
    flex-direction: column;
  }
  .main__subtitle {
    margin: 20px 0px 10px 0px;
    font-size: 20px;
  }
}