.footer {
  margin: auto 0px 0px 0px;
  width: 100%;
  background-color: var(--title-color);
}
.footer__container {
  padding: 30px 0px 20px;
}
.footer__title {
  position: relative;
  margin: 0px 0px 20px 0px;
  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: 1.15;
  color: var(--white-color);
}
.footer__contact-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
.footer__contact-item {
  margin: 0px 50px 0px 0px;
}
.footer__contact-title {
  position: relative;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  color: var(--white-color);
}
.footer__contact-caption {
  position: relative;
  margin: 7px 0px 0px 0px;
  font-weight: 400;
  font-size: 1spx;
  font-style: normal;
  line-height: 1.15;
  color: var(--white-color);
}
.footer__copy {
  position: relative;
  margin: 50px 0px 0px 0px;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 1.15;
  color: var(--white-color);
}

@media all and (max-width: 750px) {
  .footer__container {
    padding: 30px 12px 20px;
  }
  .footer__contact-list {
    display: flex;
    flex-direction: column;
  }
  .footer__contact-item {
    margin: 0px 0px 20px 0px;
    width: 100%;
  }
  .footer__copy {
    margin: 30px 0px 0px 0px;
  }
}