.popup {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0 , 0, .5);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  z-index: 100;
}
.popup_opened {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.popup__container {
  margin: 15vh auto 0px;
  padding: 34px 36px 28px;
  box-sizing: border-box;
  width: max-content;
  min-height: 114px;
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow-y: auto;
  scrollbar-color: var(--secondary-color) rgba(94, 185, 175, .3);
  scrollbar-width: 12px;
}
@-moz-document url-prefix() {
  .popup__container {
    overflow-y: scroll;
  }
}
.popup__close-button {
  position: absolute;
  padding: 0;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(../../images/close-button.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;        
  -moz-background-size: contain;          
  -o-background-size: contain;        
  cursor: pointer;
  transition: opacity .3s ease-in-out;
  outline: none;
}
.popup__close-button:hover {
  opacity: .7;
}
.popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
}
.popup__form_type_large {
  width: 900px;
}
.popup__title {
  margin: 0px 0px 25px 0px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  max-width: 550px;
  color: var(--title-color);
}
.popup__subtitle {
  margin: 0px 0px 10px 0px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  max-width: 550px;
  overflow-x: hidden;
  color: var(--title-color);
}
.popup__list-input {
  padding: 0px;
  margin: 10px 0px 0px 0px;
  list-style-type: none;
  width: 100%;
  transition: .3s ease-in-out;
}
.popup__item-input {
  width: 100%;
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 25px 0px;
  position: relative;
}
.popup__item-input_type_hide {
  display: none;
}
.popup__input-name {
  margin: 0px 10px 3px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
}
.popup__input {
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 12px 22px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  color: var(--title-color);
  letter-spacing: 1.5px;
  margin: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.popup__input::placeholder {
  font-size: 14px;
}
.popup__input-error {
  position: absolute;
  bottom: 0px;
  left: 20px;
  color: var(--main-color);
  font-size: 14px;
  line-height: 1.3;
  transition: opacity ease-in-out .3s;
  opacity: 0;
}
.popup__input-error_type_show {
  opacity: 1;
}
.popup__btn-save {
  margin: 20px 0px 0px 0px;
  width: 100%;
  padding: 11px;
  background: var(--main-color);
  border: none;
  border-radius: 8px;
  color: var(--white-color);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  transition: all .3s ease-in-out;
}
.popup__btn-save:hover  {
  cursor: pointer;
  opacity: .8;
  /*box-shadow: 0px 8px 12px -8px rgba(0, 55, 196, 0.9);*/
}
.popup__btn-save_type_block {
  background: var(--border-card-color);
  cursor: default;
  user-select: none;
  pointer-events: none;
}
.popup__btn-save_type_loading {
  background: var(--fourth-light-color);
  cursor: default;
  user-select: none;
  pointer-events: none;
}
.popup__submit-error {
  margin: 20px 0px 0px 0px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  overflow-x: hidden;
  color: var(--main-color);
}
.popup__submit-error_type_show {
  opacity: 1;
}
.popup__submit-error_type_hide {
  opacity: 0;
}

.popup__textarea {
  resize: none;
  width: 100%;
  min-height: 160px;
  padding: 9px 16px 9px 16px;
  border: 2px solid var(--border-card-color);
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--title-color);
  box-sizing: border-box;
  outline: none;
  background: var(--white-color);
  border-radius: 8px;
  transition: all .3s ease-in-out;
  overflow-y: auto;
}
.popup__textarea:hover {
  border: 2px solid var(--main-color);
}
.popup__textarea:active {
  border: 2px solid var(--main-color);
}
.popup__textarea:focus {
  border: 2px solid var(--main-color);
}
.popup__textarea_type_block {
  user-select: none;
  pointer-events: none;
}
.popup__textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--border-card-color);
}
.popup__textarea_height_minimal {
  max-height: 80px;
  min-height: 80px;
}
.popup__textarea_height_small {
  max-height: 160px;
}
.popup__textarea_height_medium {
  max-height: 300px;
}
.popup__textarea_height_max {
  max-height: 100%;
  height: 100%;
}

@media all and (max-width: 750px) {
  .popup__container {
    margin: 5vh 20px 0px;
    padding: 20px 12px 16px;
    width: calc(100% - 40px);
    min-height: 114px;
    max-height: 90vh;
  }
  .popup__form {
    width: 100%;
  }
  .popup__title {
    margin: 25px 0px 15px 0px;
  }
  .popup__item-input {
    margin: 0px 0px 10px 0px;
  }
  .popup__item-input:last-of-type {
    margin: 0px;
  }
  .popup__input {
    width: 100%;
    padding: 12px 22px;
    box-sizing: border-box;
    border-radius: 100px;
    font-size: 14px;
    line-height: 1.15;
    letter-spacing: 1.2px;
  }
  .popup__input-error {
    font-size: 12px;
  }
  .popup__submit-error {
    margin: 0px;
  }
}